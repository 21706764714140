<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["rose1", "rose2", "rose3", "rose4", "rose5", "rose6"],
        seriesData: [
          { value: 10, name: "中专/中技" },
          { value: 5, name: "大专" },
          { value: 15, name: "本科" },
          { value: 25, name: "硕士" },
          { value: 20, name: "MBA/EMBA" },
          { value: 20, name: "博士" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
