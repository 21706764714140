<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b">{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:1.25rem;height:.625rem;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">分行业企业数</span>
        <dv-scroll-ranking-board :config="ranking" style="height:2.75rem" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>今日企业通过率</span>
          <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>
        <div class="item bg-color-black">
          <span>今日职位通过率</span>
          <CenterChart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
        </div>
        <div class="water">
          <div style="text-align: center;">进行中招聘会</div>
          <dv-water-level-pond :config="water" style="height: 1.2rem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  data () {
    return {
      titleItem: [
        {
          title: "本月累计新增企业数",
          number: {
            number: [120],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "本周累计新增企业数",
          number: {
            number: [18],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "今日新增企业数",
          number: {
            number: [2],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "今年累计新增职位数",
          number: {
            number: [14],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "今周累计新增职位数",
          number: {
            number: [106],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "今日新增职位数",
          number: {
            number: [100],
            toFixed: 1,
            content: "{nt}"
          }
        }
      ],
      ranking: {
        data: [
          {
            name: "农、林、牧、渔业",
            value: 551
          },
          {
            name: "采矿业",
            value: 120
          },
          {
            name: "制造业",
            value: 782
          },
          {
            name: "电力、热力、燃气及水生产和供应业",
            value: 661
          },
          {
            name: "建筑业",
            value: 801
          },
          {
            name: "批发和零售业",
            value: 8022
          },
          {
            name: "交通运输、仓储和邮政业",
            value: 8012
          },
          {
            name: "住宿和餐饮业",
            value: 6023
          },
          {
            name: "信息传输、软件和信息技术服务业",
            value: 7041
          },
          {
            name: "金融业",
            value: 801
          },
          {
            name: "房地产业",
            value: 602
          },
          {
            name: "租赁和商务服务业",
            value: 1820
          },
          {
            name: "科学研究和技术服务业",
            value: 3810
          },
          {
            name: "水利、环境和公共设施管理业",
            value: 380
          },
          {
            name: "居民服务、修理和其他服务业",
            value: 580
          },
          {
            name: "教育",
            value: 680
          },
          {
            name: "卫生和社会工作",
            value: 180
          },
          {
            name: "文化、体育和娱乐业",
            value: 980
          },
          {
            name: "公共管理、社会保障和社会组织",
            value: 180
          },
          {
            name: "国际组织",
            value: 80
          },
        ],
        carousel: "single",
        unit: "人"
      },
      water: {
        data: [1, 3],
        shape: "roundRect",
        formatter: "{value}场",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    CenterChart
    // centerChart1,
    // centerChart2
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>
