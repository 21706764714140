<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          '福州大学',
          '福建工程学院',
          '福建农林大学',
          '福建师范大学',
          '闽江学院',
          '福建船政交通职业学院',
          '福建商学院',
          '福建警察学院',
        ],
        lineData: [
          1092,
          2728,
          2045,
          2348,
          3808,
          3097,
          3867,
          2715,
          2444,
        ],
        barData: [
          600,
          500,
          550,
          650,
          750,
          850,
          990,
          425,
          640,
        ],
        rateData: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
